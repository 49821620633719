import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const Opc = () => {
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            proposedName: '',
            proposedRegisteredOffice: '',
            attachment: '',
            relevanceName: '',
            businessDesc: '',
            proposedAuthorisedCapital: '',
            proposedIssueCapital: '',
            registeredOfficeAddress: '',
            phoneNo: '',
            emailAddress: '',
            noOfDirectors: '',
            noOfShareholders: ''
        }
    })

    // const [radioSelect, setRadioSelect] = useState('ManagingPartners')

    const colourOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
    ]

    const colourOptions2 = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
    ]

    const onSubmit = (data) => {
        console.log('Form submit', data)
        // if (data.companyType == 'private'){
        navigate('/private')
        // }

        // AddRegisterCustomer(data)
        // .then((data) => {
        //    console.log(data);
        // })
        // .catch((error) => console.log(error));

    }

    // Handle the change event
    const handleChangeDirectors = (selected) => {
        console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        console.log('Selected options values:', values);
        setValue('noOfDirectors', values)
    };

    const handleChangeShareholders = (selected) => {
        console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        console.log('Selected options values:', values);
        setValue('handleChangeShareholders', values)
    };

    return (
        <div className="container mt-2">
            <h5 class="card-title" style={{
                textAlign: 'center',
                margin: '10px',
                fontSize: '26px'
            }}>OPC Form</h5>
            <form id="incorporationForm" className='row' onSubmit={handleSubmit(onSubmit)}>
               
            <div className='col-md-6'>
                        <label for="ProposedNameInput">Person Name</label>
                        <input {...register("personName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />

                        {errors.personName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">We'll never share your email with anyone else.</small>)}
                    </div>

            <div className="form-group row">
                    <div className='col-md-6'>
                        <label for="ProposedNameInput">Proposed Name</label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />

                        {errors.proposedName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">We'll never share your email with anyone else.</small>)}
                    </div>
                    <div className='col-md-6'>
                        <label for="ProposedNameInput"></label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />
                    </div>
                </div>

                <div className="form-group col-md-12">
                    <label for="ProposedRegisteredOfficeTextArea">Proposed Registered office</label>
                    <textarea {...register("proposedRegisteredOffice", {
                        required: true,
                    })} className="form-control" aria-describedby="ProposedRegisteredOfficeHelp" id="ProposedRegisteredOfficeTextArea" rows="3" placeholder="Enter Proposed Registered office" />
                    {errors.proposedRegisteredOffice && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )} 
                </div>
                    
                <div className="form-group col-md-6">
                <label for="NameRelevanceTextArea">Add attachment</label>
                    <input type="file" name="attachment" class="form-control" id="file-input" for="file-input" style={{ opacity: '0', cursor: 'pointer' }} />
                </div>

                <div className="form-group col-md-6">
                    <label for="ProposedCaptialInput">Proposed Captial</label>
                    <input {...register("proposedCaptial", {
                        required: true,
                    })} type="text" className="form-control" id="ProposedCaptialInput" aria-describedby="ProposedCaptial" placeholder="Enter Proposed Captial Contribution by each Partner" />
                    {errors.proposedCaptial && (<small id="ProposedCaptialHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )}
                 </div>

                <div className="form-group col-md-12">
                    <label for="AddressTextArea">Registered Address</label>
                    <textarea {...register("registeredOfficeAddress", {
                        required: true,
                    })} className="form-control" aria-describedby="AddressHelp" id="AddressTextArea" rows="3" placeholder="Enter Address" />
                    {errors.emailAddress && (<small id="AddressHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )}
                </div>
                <div className='row'>
                    <div className="form-group col-md-6">
                        <label for="PhonenoInput">Registered Phone No.</label>
                        <input {...register("phoneNo", {
                            required: true,
                        })} type="text" className="form-control" id="PhonenoInput" aria-describedby="PhonenoHelp" placeholder="Enter Phone no." />
                        {errors.name && (<small id="PhonenoHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        )}
                    </div>
                    <div className="form-group col-md-6">
                        <label for="emailInput">Registered Email</label>
                        <input {...register("emailAddress", {
                            required: true,
                        })} type="text" className="form-control" id="emailInputInput" aria-describedby="emailInputHelp" placeholder="Enter Email" />
                        {errors.emailAddress && (<small id="emailInputHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        )} 
                    </div>
                </div>
               
                <div className='row'>
                <div className="form-group col-md-6">
                        <label for="BusinessActivityInput">Business activity</label>
                        <input {...register("businessActivity", {
                            required: true,
                        })} type="text" className="form-control" id="BusinessActivityInput" aria-describedby="BusinessActivityHelp" placeholder="Enter Business Activity" />
                        {errors.businessActivity && (<small id="BusinessActivityHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        )}
                    </div>
                    <div className="form-group col-md-6">
                        <label for="ProprietorDetailsInput">Proprietor details</label>
                        <input {...register("detailsOfProprietor", {
                            required: true,
                        })} type="text" className="form-control" id="ProprietorDetailsInput" aria-describedby="proprietorDetailsHelp" placeholder="Enter Email" />
                        {errors.proprietorDetails && (<small id="proprietorDetailsHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        )}
                     </div>
                </div>
               
                <div className='row'>
                    {/* {radioSelect == 'Directors' ? ( */}
                    <div className="form-group col-md-6">
                        <label for="selectDirectors">Number of Directors (minimum 2)</label>
                        {/* <input  type="text" className="form-control" id="selectDirectors" aria-describedby="selectDirectorsHelp" placeholder="Select Multiple Directors" /> */}
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions}
                            onChange={handleChangeDirectors} 
                            name="noOfDirectors" // Handle changes
                        />
                        {/* <small id="selectDirectorsHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    {/* ) : ( */}
                    <div className="form-group col-md-6">
                        <label for="selectShareholders">Number of Shareholders</label>
                        {/* <input {...register("noOfShareholders", {
                            required: true,
                        })} type="text" className="form-control" id="selectShareholders" aria-describedby="selectShareholdersHelp" placeholder="Select Multiple Shareholders" /> */}
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions2}
                            onChange={handleChangeShareholders}
                            name="noOfShareholders"  // Handle changes
                        />
                        {/* <small id="selectShareholdersHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    {/* )} */}
                </div>
              


                <div className="form-group col-md-12" style={{ textAlign: 'end', margin:'4px' }}>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>

        </div>)
}

export default Opc