import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import makeAnimated from 'react-select/animated';

// Define the Others component
const Others = () => {

    const navigate = useNavigate();
    const animatedComponents = makeAnimated();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            proposedName: '',
            proposedRegisteredOffice: '',
            attachment: '',
            relevanceName: '',
            businessDesc: '',
            proposedAuthorisedCapital: '',
            proposedIssueCapital: '',
            registeredOfficeAddress: '',
            phoneNo: '',
            emailAddress: '',
            noOfDirectors: '',
            noOfShareholders: ''
        }
    })

    const colourOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
    ]

    const colourOptions2 = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
    ]

    // const onSubmit = (data) => {
    //     console.log('Form submit', data)
        // if (data.companyType == 'private'){
        // navigate('/private')
        // }

        // AddRegisterCustomer(data)
        // .then((data) => {
        //    console.log(data);
        // })
        // .catch((error) => console.log(error));

    // }

    // Handle the change event
    const handleChangeDirectors = (selected) => {
        console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        console.log('Selected options values:', values);
        setValue('noOfDirectors', values)
    };

    const handleChangeShareholders = (selected) => {
        console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        console.log('Selected options values:', values);
        setValue('handleChangeShareholders', values)
    };


    return (
        <div>
            <div class="container mt-5">
                <h2 class="text-center mb-4">Others company</h2>
                <form id="incorporationForm">
                    <div class="form-section">
                    </div>
                </form>


                <div className="form-group row">
                    <div className='col-md-6'>
                        <label for="ProposedNameInput">Proposed Name</label>
                        <input  type="text" name='proposedName' className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" />

                        {/* {errors.proposedName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">We'll never share your email with anyone else.</small>)} */}
                    </div>
                    
                    <div className='col-md-6'>
                        <label for="ProposedNameInput"></label>
                        <input  type="text" name="proposedName" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp"  />
                    </div>
                </div>

                <label for="mobileNo" class="form-label">Registered Address</label>
                <input type="text" class="form-control" id="mobileNo" required />

                <div class="mb-3">
                    <label for="emailId" class="form-label">Registered Phone No.</label>
                    <input type="number" class="form-control" id="emailId" required />
                </div>

                <div class="mb-3">
                    <label for="permanentAddress" class="form-label">Registered Email ID</label>
                    <input type="email" className="form-control" id="anotherInput" required />
                </div>

                <div className='row'>
                    {/* {radioSelect == 'Directors' ? ( */}
                    <div className="form-group col-md-6">
                        <label for="selectDirectors">Number of Directors (minimum 2)</label>
                        {/* <input  type="text" className="form-control" id="selectDirectors" aria-describedby="selectDirectorsHelp" placeholder="Select Multiple Directors" /> */}
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions}
                            onChange={handleChangeDirectors} 
                            name="noOfDirectors" // Handle changes
                        />
                        {/* <small id="selectDirectorsHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    {/* ) : ( */}
                    <div className="form-group col-md-6">
                        <label for="selectShareholders">Number of Shareholders</label>
                        {/* <input {...register("noOfShareholders", {
                            required: true,
                        })} type="text" className="form-control" id="selectShareholders" aria-describedby="selectShareholdersHelp" placeholder="Select Multiple Shareholders" /> */}
                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions2}
                            onChange={handleChangeShareholders}
                            name="noOfShareholders"  // Handle changes
                        />
                        {/* <small id="selectShareholdersHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    {/* )} */}
                </div>
                
            <div className="form-group col-md-12" style={{ textAlign: 'end', margin:'4px' }}>
                    <button type="submit" className="btn btn-primary">Submit</button>
             </div>
             
            </div>


        </div>

    );
};

export default Others;
