import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const Llp = () => {
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            proposedName: '',
            proposedRegisteredOffice: '',
            attachment: '',
            relevanceName: '',
            businessDesc: '',
            proposedAuthorisedCapital: '',
            proposedIssueCapital: '',
            registeredOfficeAddress: '',
            phoneNo: '',
            emailAddress: '',
            noOfDirectors: '',
            noOfShareholders: ''
        }
    })

    // const [radioSelect, setRadioSelect] = useState('ManagingPartners')

    const colourOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
    ]

    const colourOptions2 = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
    ]


    const onSubmit = (data) => {
        console.log('Form submit', data)
        // if (data.companyType == 'private'){
        navigate('/private')
        // }

        // AddRegisterCustomer(data)
        // .then((data) => {
        //    console.log(data);
        // })
        // .catch((error) => console.log(error));

    }

    // Handle the change event
    const handleChangeDirectors = (selected) => {
        console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        console.log('Selected options values:', values);
        setValue('noOfDirectors', values)
    };

    const handleChangeShareholders = (selected) => {
        console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        console.log('Selected options values:', values);
        setValue('handleChangeShareholders', values)
    };

    return (
        <div className="container mt-2">
            <h5 class="card-title" style={{
                textAlign: 'center',
                margin: '10px',
                fontSize: '26px'
            }}>LLP Company</h5>
            <form id="incorporationForm" className='row' onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
                    <div className='col-md-6'>
                        <label for="ProposedNameInput">Proposed Name</label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />

                        {errors.proposedName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">We'll never share your email with anyone else.</small>)}
                    </div>
                    <div className='col-md-6'>
                        <label for="ProposedNameInput"></label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />
                    </div>
                </div>

                <div className="form-group col-md-12">
                    <label for="ProposedRegisteredOfficeTextArea">Proposed Registered office</label>
                    <textarea {...register("proposedRegisteredOffice", {
                        required: true,
                    })} className="form-control" aria-describedby="ProposedRegisteredOfficeHelp" id="ProposedRegisteredOfficeTextArea" rows="3" placeholder="Enter Proposed Registered office" />
                    {errors.proposedRegisteredOffice && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )} 
                </div>
                    
                <div className="form-group col-md-6">
                <label for="NameRelevanceTextArea">Add attachment</label> 
                    <input type="file" anme="attachment" class="form-control" id="file-input" for="file-input" />
                </div>

                <div className="form-group col-md-12">
                    <label for="NameRelevanceTextArea">Name Relevance (Description of Choosen Name)</label>
                    <textarea {...register("relevanceName", {
                        required: true,
                    })} className="form-control" aria-describedby="NameRelevanceHelp" id="NameRelevanceTextArea" rows="3" placeholder="Enter Name Relevance (Description of Choosen Name)" />
                    {errors.relevanceName && (<small id="NameRelevanceHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )} 
                </div>

                <div className="form-group col-md-12">
                    <label for="ObjTextArea">Object - Business to be Carried Out</label>
                    <textarea {...register("businessDesc", {
                        required: true,
                    })} className="form-control" aria-describedby="ObjTextAreaHelp" id="ObjTextArea" rows="3" placeholder="Enter Object - Business to be Carried Out" />
                    {errors.businessDesc && (<small id="ObjTextAreaHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )} 
                </div>

                <div className="form-group col-md-6">
                    <label for="ProposedCaptialContributionByEachPartnerInput">Proposed Captial Contribution by each Partner</label>
                    <input {...register("proposedCapitalContribution", {
                        required: true,
                    })} type="text" className="form-control" id="ProposedCaptialContributionByEachPartnerInput" aria-describedby="ProposedCaptialContributionByEachPartner" placeholder="Enter Proposed Captial Contribution by each Partner" />
                    {errors.proposedCaptialContributionByEachPartner && (<small id="ProposedCaptialContributionByEachPartnerHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )} 
                </div>
                
                <div className="form-group col-md-6">
                    <label for="ProposedProfitSharingRatioInput">Proposed Profit Sharing Ratio</label>
                    <input {...register("proposedProfitSharingRatio", {
                        required: true,
                    })} type="text" className="form-control" id="ProposedProfitSharingRatioInput" aria-describedby="ProposedProfitSharingRatioHelp" placeholder="Enter Proposed Profit Sharing Ratio" />
                    {errors.proposedProfitSharingRatio && (<small id="ProposedProfitSharingRatioHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )}
                </div>
                    
                <div className="form-group col-md-12">
                    <label for="ProposeAnySpecialArrangementBetweenPartnersTextArea">Propose any special arrangement between partners</label>
                    <textarea {...register("proposedSpecialArrangement", {
                        required: true,
                    })} className="form-control" aria-describedby="ProposeAnySpecialArrangementBetweenPartnersHelp" id="ProposeAnySpecialArrangementBetweenPartnersTextArea" rows="3" placeholder="Enter Propose any special arrangement between partners" />
                    {errors.phoneNo && (<small id="ProposeAnySpecialArrangementBetweenPartnersHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )}
                </div>

                <div className="form-group col-md-12">
                    <label for="AddressTextArea">Registered Address</label>
                    <textarea {...register("registeredOfficeAddress", {
                        required: true,
                    })} className="form-control" aria-describedby="AddressHelp" id="AddressTextArea" rows="3" placeholder="Enter Address" />
                    {errors.emailAddress && (<small id="AddressHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    )}
                </div>

                <div className='row'>
                    <div className="form-group col-md-6">
                        <label for="PhonenoInput">Registered Phone No.</label>
                        <input {...register("name", {
                            required: true,
                        })} type="text" className="form-control" id="PhonenoInput" aria-describedby="PhonenoHelp" placeholder="Enter Phone no." />
                        {errors.name && (<small id="PhonenoHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        )}</div>
                    <div className="form-group col-md-6">
                        <label for="emailInput"> Registered Email</label>
                        <input {...register("emailAddress", {
                            required: true,
                        })} type="text" className="form-control" id="emailInputInput" aria-describedby="emailInputHelp" placeholder="Enter Email" />
                        {errors.emailAddress && (<small id="emailInputHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        )} </div>
                </div>

                <div className='row'>
                    <div className="form-group col-md-6">
                        <label for="CorporatePartnerInput">Number of Corporate Partner</label>
                        <input {...register("noOfCorporatePartner", {
                            required: true,
                        })} type="text" className="form-control" id="CorporatePartnerInput" aria-describedby="CorporatePartnerHelp" placeholder="Enter number of Corporate Partner" />
                        {errors.name && (<small id="CorporatePartnerHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        )}
                        </div>

                    <div className="form-group col-md-6">
                        <label for="PartnersDetailsInput">Partners Details as per Directors Sheet</label>
                        <input {...register("partnerDetails", {
                            required: true,
                        })} type="text" className="form-control" id="PartnersDetailsInput" aria-describedby="PartnersDetailsHelp" placeholder="Enter Partners Details as per Directors Sheet" />
                        {errors.partnersDetails && (<small id="PartnersDetailsHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        )}
                 </div>
                </div>

                <div className='row'>
                    {/* {radioSelect == 'Directors' ? ( */}
                        <div className="form-group col-md-6">
                            <label for="selectDirectors">Number of Directors (minimum 2)</label>
                            <select class="form-select" {...register("noOfDirectors", { required: true, })} aria-label="Default select example">
                            <option selected>select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                            {/* <input  type="text" className="form-control" id="selectDirectors" aria-describedby="selectDirectorsHelp" placeholder="Select Multiple Directors" /> */}
                            {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={[colourOptions[4], colourOptions[5]]}
                                isMulti
                                options={colourOptions}
                                onChange={handleChangeDirectors} 
                                name="noOfDirectors" // Handle changes
                            /> */}
                            {/* <small id="selectDirectorsHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                    {/* ) : ( */}
                        <div className="form-group col-md-6">
                            <label for="selectShareholders">Number of Shareholders</label>
                            <select class="form-select"  aria-label="Default select example">
                            <option selected>select </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                        </select>
                            {/* <input {...register("noOfShareholders", {
                            required: true,
                        })} type="text" className="form-control" id="selectShareholders" aria-describedby="selectShareholdersHelp" placeholder="Select Multiple Shareholders" /> */}
                            {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={[colourOptions[4], colourOptions[5]]}
                                isMulti
                                options={colourOptions2}
                                onChange={handleChangeShareholders} 
                                name="noOfShareholders" // Handle changes
                            /> */}
                            {/* <small id="selectShareholdersHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                    {/* )} */}
                </div>
               
               


                <div className="form-group col-md-12" style={{ textAlign: 'end', margin:'4px' }}>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>

        </div>)
}

export default Llp