import { Route, Routes } from 'react-router-dom';
import './App.css';
import Director from './pages/director/Director';
import Llp from './pages/llp/Llp';
import Main from './pages/main/Main';
import Opc from './pages/opc/Opc';
import Others from './pages/others/Others';
import Private from './pages/private/Private';
import PublicComapny from './pages/public/Public';
import Shareholder from './pages/shareholder/Shareholder';
import Other from './pages/others/Others';



function App() {
  return (

    <Routes>
      <Route path='/' element={<Main />} />
      <Route path='*' element={<Main />} />
      <Route path='/llp' element={<Llp />} />
      <Route path='/director' element={<Director />} />
      <Route path='/opc' element={<Opc />} />
      <Route path='/others' element={<Others />} />
      <Route path='/private' element={<Private />} />
      <Route path='/public' element={<PublicComapny />} />
      <Route path='/shareholder' element={<Shareholder />} />
      <Route path='/other' element={<Other />} />
    </Routes>

  );
}

export default App;
