import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Button } from 'react-bootstrap'; // Make sure you have react-bootstrap installed
import uploadFileToBlob, { handlePriview } from '../../helpers/azureBlob';
import { AddRegisterCustomer } from '../../helpers/PostRquest/postRquest';
import { useNavigate } from 'react-router-dom';

const DynamicForm = () => {
    const [numberOfDirectorDropdowns, setNumberOfDirectorDropdowns] = useState('1');
    useEffect(() => {
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);
        //  console.log(x.entityForm.noOfDirectors);

         setNumberOfDirectorDropdowns(x.entityForm.noOfDirectors)
    }, [])

    // Initialize state for all forms
    const [directorFormData, setDirectorFormData] = useState(
        Array.from({ length: numberOfDirectorDropdowns }, () => ({
            name: '',
            fatherName: '',
            dateOfBirth: '',
            nationality: '',
            gender: '',
            placeOfBirthDistrict: '',
            placeOfBirthState: '',
            occupation: '',
            educationalQualification: '',
            panCopy: null,
            passportCopy: null,
            mobileNo: '',
            emailId: '',
            permanentAddress: '',
            presentAddress: '',
            previousResidence: '',
            proofOfIdentity: '',
            residentialProof: '',
            sharesSubscribed: 2000,
            entitiesInterest: '',
            sharePercentage: '',
            shareHoldingAmount: '',
            passportSizePhoto: null,
            din: ''
        }))
    );

    // Handle input change
    const handleDirectorInputChange = async (index, event) => {
       
        const { name, value, type, files } = event.target;
        const newFormData = [...directorFormData];
      
        // Ensure that newFormData[index] is initialized
        if (index < 0 || index >= newFormData.length) {
          console.error("Index out of bounds");
          return;
        }
        
        if (!newFormData[index]) {
          newFormData[index] = {}; // Initialize if undefined
        }
        if (type === 'file') {
           
            if(!(event.target.files[0])){
                return
            }
            
            // *** UPLOAD TO AZURE STORAGE ***
            const blobUrl = await uploadFileToBlob(event.target.files[0]).then((res) => {
                newFormData[index][name] = handlePriview(event.target.files[0].name);
            });
        } else {
            newFormData[index][name] = value;
        }
        setDirectorFormData(newFormData);
        console.log(newFormData);
        
    };

    useEffect(() => {
        setDirectorFormData(prevData => 
          Array.from({ length: numberOfDirectorDropdowns }, (_, i) => 
            prevData[i] || { name: '', fatherName: '', /* other fields */ }
          )
        );
      }, [numberOfDirectorDropdowns]);
      
      const navigate = useNavigate();
    // Handle submit
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form Data:', directorFormData);
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);
     
        x.directors=directorFormData;
        AddRegisterCustomer(x)
        .then((data) => {
            console.log(data);
            localStorage.setItem('FormData', JSON.stringify(data))
               
                 if(data.shareholder != '0') {
                    navigate('/shareholder')
                }
                else{
                    alert('no shareholder selected')
                }

            })
            .catch((error) => console.log(error));
    };

    // const handleDirectorPanCopyInputDocChange =async (index, e)=>{
    //     if(!(e.target.files[0])){
    //         return
    //     }
        
    //     // *** UPLOAD TO AZURE STORAGE ***
    //     const blobUrl = await uploadFileToBlob(e.target.files[0]).then((res) => {
    //         handleDirectorInputChange(index,`${handlePriview(e.target.files[0].name)}`)
 
    //     });
    // }
    
   

    // Create an array of dropdowns with forms inside
    const directorDropdowns = Array.from({ length: numberOfDirectorDropdowns }, (_, index) => (
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center", alignContent: "center" }}>
            <Dropdown key={index} className="mb-3 mt-5"  >
                <Button
                    className="btn btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#dropdown-basic-${index}`}
                    aria-expanded="false"
                    aria-controls={`dropdown-basic-${index}`}
                >
                    Director details {index + 1}
                </Button>

                <div className="collapse" id={`dropdown-basic-${index}`}>
                    <div className="card card-body" style={{ width: "700px", alignItems: "center" }}>
                        <Form style={{ width: "500px", alignContent: "center" }}>
                            <Form.Group controlId={`form${index}`}>
                                <Form.Label>Form {index + 1} Input</Form.Label>

                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        // value={directorFormData[index].name}
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Father Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="fatherName"
                                        // value={directorFormData[index].fatherName}
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dateOfBirth"
                                        // value={directorFormData[index].dateOfBirth}
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Nationality</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nationality"
                                        // value={directorFormData[index].nationality}
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select
                                        name="gender"
                                        // value={directorFormData[index].gender}
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    >
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Add other fields similarly */}

                                <Form.Group className="mb-3">
                                    <Form.Label>Copy of PAN (Scan & Share)</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="panCopy"
                                        onChange={(event) => handleDirectorInputChange(index, event)} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Passport (If Foreign National)</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="passportCopy"
                                        onChange={(event) => handleDirectorInputChange(index, event)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Passport Size Photo</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="passportSizePhoto"
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Place Of Birth</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="placeOfBirth"
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Occupation</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="occupation"
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Educational Qualification</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="educationalQualification"
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Mobile no.</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="mobileNo"
                                        onChange={(event) => handleDirectorInputChange(index, event)}
                                    />
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="emailAddress"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Permanent Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="permanentAddress"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Present Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="presentAddress"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Stay Duration Present Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="stayDurationPresentAddress"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Present Address If Less Than Year</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="presentAddressIfLessThanYear"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Proof Of Identity</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="proofOfIdentity"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Residential Proof</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="residentialProof"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>No Of Shares Subscribed</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="noOfSharesSubscribed"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Director Entity Count</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="directorEntityCount"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Director Identification No</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="directorIdentificationNo"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                </Form.Group>

                                {/* Add other fields similarly */}

                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </Dropdown>
        </div>
    ));



    return (
        <div  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <form className="form-group col-md-12" onSubmit={handleSubmit} style={{  display: "flex", flexDirection: "column", width: "100%", maxWidth: "200px" }} >
                {directorDropdowns}

                <Button variant="primary" className="form-group" type="submit" style={{ display: "flex", justifyContent: "center"}} >Submit</Button>
            </form>
        </div>
    );
};

export default DynamicForm;



