import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Button } from 'react-bootstrap'; // Make sure you have react-bootstrap installed
import { AddRegisterCustomer } from '../../helpers/PostRquest/postRquest';
import uploadFileToBlob, { handlePriview } from '../../helpers/azureBlob';
import { useNavigate } from 'react-router-dom';

const Shareholder = () => {
    const [numberOfShareholderDropdowns, setNumberOfShareholderDropdowns] = useState('1');
    useEffect(() => {
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);
        //  console.log(x.entityForm.noOfShareholders);

         setNumberOfShareholderDropdowns(x.entityForm.noOfShareholders)
    }, [])
    const navigate = useNavigate();
    // Handle submit
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form Data:', shareholderFormData);
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);
     
        x.shareholders=shareholderFormData;
        console.log('Form Data:', shareholderFormData);
    
        // Process form data (e.g., send to an API)
        AddRegisterCustomer(x)
        .then((data) => {
           console.log(data);
           navigate('/')
        })
        .catch((error) => console.log(error));
    };

    // Shareholders

    // const numberOfShareholderDropdowns = 5;

    // Initialize state for all forms
    const [shareholderFormData, setShareholderFormData] = useState(
        Array.from({ length: numberOfShareholderDropdowns }, () => ({
            name: '',
      fatherName: '',
      dateOfBirth: '',
      nationality: '',
      placeOfBirth: '',
      occupation: '',
      educationalQualification: '',
      copyOfPAN: '',
      passport: '',
      mobileNo: '',
      emailAddress: '',
      permanentAddress: '',
      presentAddress: '',
      stayDurationPresentAddress: '',
      presentAddressIfLessThanYear: '',
      proofOfIdentity: '',
      residentialProof: '',
      noOfSharesSubscribed: 0,
      shareholderEntityCount: 0,
      percentageOfShareHolding: 0,
      passportSizePhoto: '',
      shareholderIdentificationNo: ''
        }))
    );

    // Handle input change
    const handleShareholderInputChange = async (index, event) => {
       
        const { name, value, type, files } = event.target;
        const newFormData = [...shareholderFormData];
      
        // Ensure that newFormData[index] is initialized
        if (index < 0 || index >= newFormData.length) {
          console.error("Index out of bounds");
          return;
        }
        
        if (!newFormData[index]) {
          newFormData[index] = {}; // Initialize if undefined
        }
        if (type === 'file') {
           
            if(!(event.target.files[0])){
                return
            }
            
            // *** UPLOAD TO AZURE STORAGE ***
            const blobUrl = await uploadFileToBlob(event.target.files[0]).then((res) => {
                newFormData[index][name] = handlePriview(event.target.files[0].name);
            });
        } else {
            newFormData[index][name] = value;
        }
        setShareholderFormData(newFormData);
        console.log(newFormData);
        
    };

    // // Handle submit
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     console.log('Form Data:', shareholderFormData);
    //     // Process form data (e.g., send to an API)
    // };

    // Create an array of dropdowns with forms inside
    const shareholdersDropdowns = Array.from({ length: numberOfShareholderDropdowns }, (_, index) => (
        <Dropdown key={index} className="mb-3">
            <Button 
                className="btn btn-primary" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target={`#shareholder-dropdown-basic-${index}`} 
                aria-expanded="false" 
                aria-controls={`shareholder-dropdown-basic-${index}`}
            >
                Shareholder details {index + 1}
            </Button>

            <div className="collapse" id={`shareholder-dropdown-basic-${index}`}>
                <div className="card card-body"  style={{ width: "750px", alignItems: "center" }}>
                    <Form  style={{ width: "550px", alignContent: "center" }}>
                        <Form.Group controlId={`form${index}`}>
                            <Form.Label>Form {index + 1} Input</Form.Label>

                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={shareholderFormData[index].name}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Father Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fatherName"
                                    value={shareholderFormData[index].fatherName}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="dateOfBirth"
                                    value={shareholderFormData[index].dateOfBirth}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Nationality</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="nationality"
                                    value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Occupation</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="occupation"
                                    value={shareholderFormData[index].occupation}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Education Qualification</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="educationalQualification"
                                    value={shareholderFormData[index].educationalQualification}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Copy of PAN (Scan & Share)</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="copyOfPAN"
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Passport (If Foreign National)</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="passport"
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="mobileNo"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="emailAddress"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Permanent Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="permanentAddress"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Present Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="presentAddress"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Duration of stay at present address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="stayDurationPresentAddress"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>If Duration of stay at present address is less than one year then address of previous residence </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="presentAddressIfLessThanYear"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Proof of identity(any one of them) Voter ID/ Passport/ Driving Licence</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="proofOfIdentity"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Residential Proof (any one of them) Electricity Bill/ Bank Statement / Telephone Bill / Mobile Bill</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="residentialProof"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>
                          
                            <Form.Group className="mb-3">
                                <Form.Label>No. of Shares Subscribed by each Subscriber  </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="noOfSharesSubscribed"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Number of entities in which Shareholder have interest, - Mention the number of companies they are directors & share holders in</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="shareholderEntityCount"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>Percentage of Share holding and amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="percentageOfShareHolding"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>Passport size photo</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="passportSizePhoto"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>Director Identification No. (DIN) If any</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="shareholderIdentificationNo"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>Copy of electricity bill (Not older than 2 months)</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="copyOfElectricityBill"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Copy of rent agreement or lease deed (If property is rented)</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="copyOfNoc"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Copy of NOC (No Objection Certificate) from the landlord if property is name of other than director or Subscriber</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="copyOfRentAggrement"
                                    // value={shareholderFormData[index].nationality}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                />
                            </Form.Group>

                            {/* <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select
                                    name="gender"
                                    value={shareholderFormData[index].gender}
                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                >
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </Form.Select>
                            </Form.Group> */}

                            {/* Add other fields similarly */}
                            
                            

                            {/* Add other fields similarly */}

                        </Form.Group>
                    </Form>
                </div>
            </div>
        </Dropdown>
    ));

    return (
        <div  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <form onSubmit={handleSubmit}  >
            
            {shareholdersDropdowns}
            <Button variant="primary" type="submit"  style={{ display: "flex", justifyContent: "center"}}>Submit</Button>
        </form>
        </div>
    );
};

export default Shareholder;




