import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AddRegisterCustomer } from '../../helpers/PostRquest/postRquest';
import uploadFileToBlob, { handlePriview } from '../../helpers/azureBlob';

const Public = () => {
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            proposedName: '',
            proposedRegisteredOffice: '',
            attachment: '',
            relevanceName: '',
            businessDesc: '',
            proposedAuthorisedCapital: '',
            proposedIssueCapital: '',
            registeredOfficeAddress: '',
            phoneNo: '',
            emailAddress: '',
            noOfDirectors: '',
            noOfShareholders: ''
        }
    })

    const [radioSelect, setRadioSelect] = useState('Directors')

    const colourOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
    ]

    const colourOptions2 = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
    ]

   

    const onSubmit = (data) => {
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);
         console.log(x.id);
        
        // data.attachment = base64String;
        console.log('Form submit', data)
        
            // id: parseInt('1063'),
            // entityForm: 
            
        x.entityForm = {
            proposedName: data.proposedName,
            proposedRegisteredOffice: data.proposedRegisteredOffice,
            attachment: base64String,
            relevanceName: data.relevanceName,
            businessDesc: data.businessDesc,
            proposedAuthorisedCapital: parseInt(data.proposedAuthorisedCapital),
            proposedIssueCapital: parseInt(data.proposedIssueCapital),
            registeredOfficeAddress: data.registeredOfficeAddress,
            phoneNo: data.phoneNo,
            emailAddress: data.emailAddress,
            noOfDirectors: parseInt(data.noOfDirectors),
            noOfShareholders: parseInt(data.noOfShareholders)
        };
        AddRegisterCustomer(x)
        .then((data) => {
            console.log(data);
            localStorage.setItem('FormData', JSON.stringify(data))
               
                if (data.directors != '0') {
                    navigate('/director')
                }
                else if(data.shareholder != '0') {
                    navigate('/shareholder')
                }
                else{
                    alert('no director and shareholder selected')
                }

            })
            .catch((error) => console.log(error));

    }

    // Handle the change event
    const handleChangeDirectors = (selected) => {
        console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        console.log('Selected options values:', values);
        setValue('noOfDirectors', values)
    };

    const handleChangeShareholders = (selected) => {
        console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        console.log('Selected options values:', values);
        setValue('handleChangeShareholders', values)
    };

    const [base64String, setBase64String] = useState('');
    const [sharebase64String, setShareBase64String] = useState('');
    const handleFileChange = async (e) => {
        // const file = event.target.files[0];
        // if (file) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setBase64String(reader.result.split(',')[1]); // Get Base64 string without the data URL prefix
        //     };
        //     reader.readAsDataURL(file);
        // }

        if(!(e.target.files[0])){
            return
        }else{
            setShareBase64String(e.target.files[0])
        }
        
       
    };
    

    return (
        <div className="container mt-2">
            <h5 class="card-title" style={{
                textAlign: 'center',
                margin: '10px',
                fontSize: '26px'
            }}>Public Company</h5>
            <form id="incorporationForm" className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                    <div className='col-md-6'>
                        <label for="ProposedNameInput">Proposed Name</label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />

                        {errors.proposedName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Please enter correct proposed name</small>)}
                    </div>
                    {/* <div className='col-md-6'>
                        <label for="ProposedNameInput"></label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />
                    </div> */}
                </div>
                <div className="form-group col-md-12">
                    <label for="ProposedRegisteredOfficeTextArea">Proposed Registered office</label>
                    <textarea {...register("proposedRegisteredOffice", {
                        required: true,
                    })} className="form-control" aria-describedby="ProposedRegisteredOfficeHelp" id="ProposedRegisteredOfficeTextArea" rows="3" placeholder="Enter Proposed Registered office" />
                    {errors.proposedRegisteredOffice && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted">Please enter correct proposed registered office</small>
                    )}
                </div>

                <div className="form-group col-md-6">
                    <label for="NameRelevanceTextArea" >Add attachment</label>
                    <input type="file" onChange={handleFileChange} name="attachment" className="form-control" id="file-input" for="file-input" />
                    {/* {errors.proposedRegisteredOffice && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted">Please enter correct proposed registered office</small>
                    )} */}
                </div>

                <div className="form-group col-md-12">
                    <label for="NameRelevanceTextArea">Name Relevance (Description of Choosen Name)</label>
                    <textarea {...register("relevanceName", {
                        required: true,
                    })} className="form-control" aria-describedby="NameRelevanceHelp" id="NameRelevanceTextArea" rows="3" placeholder="Enter Name Relevance (Description of Choosen Name)" />
                    {errors.relevanceName && (<small id="NameRelevanceHelp" className="form-text text-muted">Please enter correct name relevance</small>
                    )}
                </div>

                <div className="form-group col-md-12">
                    <label for="ObjTextArea">Object - Business to be Carried Out</label>
                    <textarea {...register("businessDesc", {
                        required: true,
                    })} className="form-control" aria-describedby="ObjTextAreaHelp" id="ObjTextArea" rows="3" placeholder="Enter Object - Business to be Carried Out" />
                    {errors.businessDesc && (<small id="ObjTextAreaHelp" className="form-text text-muted">Please enter correct Object business to be carried out</small>
                    )}
                </div>

                <div className="form-group col-md-6">
                    <label for="ProposedAuthorizedCapitalInput">Proposed Authorized Capital</label>
                    <input {...register("proposedAuthorisedCapital", {
                        required: true,
                    })} type="number" className="form-control" id="ProposedAuthorizedCapitalInput" aria-describedby="ProposedAuthorizedCapitalHelp" placeholder="Enter Proposed Authorized Capital" />
                    {errors.proposedAuthorisedCapital && (<small id="ProposedAuthorizedCapitalHelp" className="form-text text-muted">Please enter correct proposed authorized capital</small>
                    )}
                </div>

                <div className="form-group col-md-6">
                    <label for="ProposedIssuedCapitalInput">Proposed Issued Capital</label>
                    <input {...register("proposedIssueCapital", {
                        required: true,
                    })} type="number" className="form-control" id="ProposedIssuedCapitalInput" aria-describedby="ProposedIssuedCapitalHelp" placeholder="Enter Proposed Issued Capital" />
                    {errors.proposedIssueCapital && (<small id="ProposedIssuedCapitalHelp" className="form-text text-muted">Please enter correct proposed issued capital</small>
                    )}
                </div>

                <div className="form-group col-md-12">
                    <label for="AddressTextArea"> Registered Address</label>
                    <textarea {...register("registeredOfficeAddress", {
                        required: true,
                    })} className="form-control" aria-describedby="AddressHelp" id="AddressTextArea" rows="3" placeholder="Enter Address" />
                    {errors.emailAddress && (<small id="AddressHelp" className="form-text text-muted">Please enter correct registered address</small>
                    )}
                </div>

                <div className='row'>
                    <div className="form-group col-md-6">
                        <label for="PhonenoInput"> Registered Phone No.</label>
                        <input {...register("phoneNo", {
                            required: true,
                        })} type="number" className="form-control" id="PhonenoInput" aria-describedby="PhonenoHelp" placeholder="Enter Phone no." />
                        {errors.name && (<small id="PhonenoHelp" className="form-text text-muted">Please enter correct registered phone no.</small>
                        )}
                    </div>

                    <div className="form-group col-md-6">
                        <label for="emailInput"> Registered Email</label>
                        <input {...register("emailAddress", {
                            required: true,
                        })} type="email" className="form-control" id="emailInputInput" aria-describedby="emailInputHelp" placeholder="Enter Email" />
                        {errors.emailAddress && (<small id="emailInputHelp" className="form-text text-muted">Please enter correct registered email</small>
                        )}
                    </div>

                </div>

                <div className='row'>

                    <div className="form-group col-md-6">
                        <label for="selectDirectors">Number of Directors (minimum 2)</label>
                        {/* <input  type="text" className="form-control" id="selectDirectors" aria-describedby="selectDirectorsHelp" placeholder="Select Multiple Directors" /> */}
                        {/* <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions}
                            onChange={handleChangeDirectors}
                            name="noOfDirectors" // Handle changes
                        /> */}
                        <select className="form-control" id="budget" name="budget"
                                        {...register("noOfDirectors", {
                                            required: true,
                                        })}>
                                        <option selected value="">--Select--</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                    {errors.noOfDirectors && (<small id="noOfDirectorsSelectHelp" className="form-text text-muted">Please select atleast two directors</small>
                        )}
                                   
                    </div>

                    <div className="form-group col-md-6">
                        <label for="selectShareholders">Number of Shareholders</label>
                        {/* <input {...register("noOfShareholders", {
                            required: true,
                        })} type="text" className="form-control" id="selectShareholders" aria-describedby="selectShareholdersHelp" placeholder="Select Multiple Shareholders" /> */}
                        {/* <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions2}
                            onChange={handleChangeShareholders}
                            name="noOfShareholders"
                        
                        /> */}
                          <select className="form-control" id="budget" name="budget"
                                        {...register("noOfShareholders", {
                                            required: true,
                                        })}>
                                        <option selected value="">--Select--</option>
                                        <option value="1">1 </option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                    {errors.noOfShareholders && (<small id="noOfShareholdersSelectHelp" className="form-text text-muted">Please select atleast one shareholders</small>
                        )}
                    </div>

                </div>


                <div className="form-group col-md-12" style={{ textAlign: 'end' }}>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>

        </div>)
}

export default Public